import React from "react";
import { Container } from "react-bootstrap";

function Blogs() {
  return (
    <Container fluid className="project-section">
    <Container>
      <h1 className="purple">Coming Soon..</h1>
    </Container>
    </Container>
  );
}

export default Blogs;
